.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 5;
    transition: box-shadow .3s;
}

.header .row {
    height: 8rem;
    padding: 1rem 2%;
    align-items: center;
}

.header.active {
    background: var(--bg);
    box-shadow: 0 1rem 5rem 0rem var(--shadow);
    /* color: var(--white); */
}

/* .header.active .hamburger span {
    background: var(--white);
} */

header .heading {
    align-items: center;
    gap: 1rem;
    flex: 1;
}

.heading .logo {
    width: 5rem;
    cursor: pointer;
    height: 5rem;
}
.heading .logo img{
    user-select: none;
    filter: drop-shadow(0 4px 4px var(--shadow)); /* Fixed syntax */
}

.heading .text {
    cursor: pointer;
}

.header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}



.go-to-top {
    width: 3em;
    height: 3em;
    position: fixed;
    bottom: 5%;
    right: 5%;
    cursor: pointer;
    z-index: 10;
    padding: 1rem;
    background: var(--secondary);
    border: 1px solid rgba(100,100,100,.1);
    border-radius: 10px;
    color: var(--white);
    transform: translateY(100%);
    visibility: hidden;
    transition: all .3s, visibility .1s;
}

.go-to-top.visible {
    transform: translateY(0%);
    visibility: visible;
}

.go-to-top:hover {
    box-shadow: 0 1rem 3rem -1rem var(--shadow);
    transform: translateY(0%) scale(1.2);
}




/* Responsive */

@media screen and (max-width: 1050px) {

    .header-actions {
        position: absolute;
        z-index: 5;
        top: 8rem;
        right: -100%;
        flex-direction: column-reverse;
        width: 100%;
        height: calc(100vh - 8rem);
        max-width: 50rem;
        box-shadow: -2rem 4.8rem 5rem 0rem var(--shadow);
        transition: 1s;
        background: var(--bg);
    }

    .header .row {
        width: 100%;
    }

    .header-actions.active {
        padding: 1rem 3rem;
        right: 0;
        transition: .3s;
    }

    .header-actions .navbar .nav-list {
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
    }

    .header-actions.active .navbar.active .nav-list li {
        width: 100%;
    }

    .header-actions.active .navbar.active .nav-list li a {
        padding: 2rem 0;
        gap: 1rem;
    }

    .header-actions.active .row {
        justify-content: space-between;
    }

}