.login-page {
    justify-content: center;
}

.login-container {
    display: flex;
    flex-direction: column;
    background: var(--bg);
    padding: 3rem;
    border-radius: 10px;
    border: .1rem solid var(--bg-dark);
    box-shadow: 0rem 2rem 2rem -1rem rgba(0, 0, 0, .5);
    min-width: 35rem;
    width: 40vw;
}

.login-container .login-heading {
    font-size: var(--large-font);
    padding: 0 0 1rem 0;
    text-align: center;
    margin-bottom: 2rem;
    margin-left: 2rem;
    align-self: flex-start;
    border-bottom: .5rem solid var(--primary);
}


.login-container .login-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.input-box input#Username {
    text-transform: uppercase;
}