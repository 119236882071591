.page {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    min-height: calc(100svh - 8rem);
    position: relative;
}

.page .page-heading {

    margin: 1rem 0;
    font-size: var(--heading);
    padding: 1rem;
}