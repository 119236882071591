.my-courses-page .page-heading {
    font-size: 3rem;
    padding: 1rem 3rem;
    margin: 1rem 0;
    color: var(--text);
    font-weight: 700;
}

.not-found h2{
    color: var(--danger);
}
.not-found
{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-column: span 6;
    gap: 1rem;
    font-size: 2rem;
    padding: 1rem 3rem;
    margin: 1rem 0;
    color: var(--text);
}

.my-courses-page .courses-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    padding: 2rem 5%;
    align-items: center;
    align-self: flex-start;
    gap: 2rem;
}