.hero-section {
    height: calc(100dvh - 10rem);
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 3rem;
}

.hero-section .hero-section-images {
    width: 100%;
    height: 100%;
    user-select: none;

}

.hero-section .hero-section-images img {
    position: absolute;
    width: auto;
    height: 100%;
    overflow: hidden;
    user-select: none;
    -webkit-user-drag: none;

}

.hero-section .hero-section-images img:first-child {
    left: 17%;
    top: 20%;
    height: 70%;
}

.hero-section .hero-section-images img:nth-child(2) {
    left: 50%;
    bottom: 35%;
    transform: translate(-50%, 50%);
    height: 25%;
}

.hero-section .hero-section-images img:last-child {
    right: 15%;
    top: 8%;
    height: 90%;
}

.hero-section .hero-section-content {
    position: absolute;
    left: 50%;
    top: 12%;
    transform: translateX(-50%);
    width: 50%;
    text-align: center;
    padding: 2rem;
    background: transparent;
    backdrop-filter: blur(15px);
    border: 1px solid rgba(100, 100, 100, 0.223);
    border-radius: 10px;
}

.hero-section-content .hero-section-heading {
    font-size: 4rem;
    color: var(--primary);
    text-shadow: -1px 1px 1px var(--black);
}

.hero-section-content .hero-section-sub-heading {
    font-size: var(--large-font);
    color: var(--secondary);
    text-shadow: 0 1px 1px var(--black);
}

.hero-section-content .hero-section-description {
    font-size: var(--small-font);
    padding: 1rem 0;
}

.hero-section .scroll-down {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}

.scroll-down .scroll-down-btn {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
    background: var(--bg-light);
    border: .2rem solid var(--primary);
    border: none;
    border-radius: 5rem;
    cursor: pointer;
    color: var(--text);
    transition: .3s;
    animation: scroll 2s cubic-bezier(1, -0.6, 0.2, 0.95) infinite;
}

@keyframes scroll {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(50%);
    }
}

.scroll-down .scroll-down-btn:hover {
    background: var(--primary);
    color: var(--white);
    animation: none;
}

.scroll-down .scroll-down-btn:hover .icon {
    color: var(--white);
}

.scroll-down-btn .text,
.scroll-down-btn .icon i {
    color: inherit;
}


/* Responsive */

@media screen and (max-width:1368px) {

    .hero-section .hero-section-content {
        width: 45%;
    }

}

@media screen and (max-width:1200px) {

    .hero-section .hero-section-images img:first-child {
        left: 10%;
    }

    .hero-section .hero-section-images img:last-child {
        right: 10%;
    }

    .hero-section-content .hero-section-heading {
        font-size: 3rem;
    }
}

@media screen and (max-width:1070px) {


    .hero-section .hero-section-images img:first-child {
        left: 8%;
        top: 15%;
    }

    .hero-section .hero-section-images img:last-child {
        right: 5%;
        top: 5%;
    }


    .hero-section .hero-section-content {
        top: 10%;
    }

    .hero-section-content .hero-section-heading {
        font-size: 3rem;
    }

    .hero-section-content .hero-section-sub-heading {
        font-size: var(--medium-font);
    }

}

@media screen and (max-width:884px) {



    .hero-section .hero-section-images img:first-child {
        left: 8%;
        top: 17%;
        height: 60%;
    }

    .hero-section .hero-section-images img:last-child {
        right: 5%;
        top: 10%;
        height: 70%;
    }


    .hero-section .hero-section-content {
        top: 10%;
        width: 50%;
    }

    .hero-section-content .hero-section-heading {
        font-size: 3rem;
    }

}

@media screen and (max-width:750px) {

    .hero-section .hero-section-content {
        width: 60%;
    }

    .hero-section .hero-section-images img:first-child {
        left: 0%;
        top: 15%;
        height: 50%;
    }

    .hero-section .hero-section-images img:last-child {
        right: 0%;
        top: 9%;
        height: 60%;
    }


}


@media screen and (max-width:600px) {


    .hero-section .hero-section-images img:first-child,
    .hero-section .hero-section-images img:last-child {
        height: 0;
        width: 0;
    }

    .hero-section .hero-section-images img:nth-child(2) {
        top: 8rem;
    }

    .hero-section .hero-section-content {
        width: 100%;
        top: 6%;
    }

    .hero-section-content .hero-section-heading {
        font-size: 4.5rem;
    }

    .hero-section-content .hero-section-sub-heading {
        font-size: var(--large-font);
    }

    .hero-section-content .hero-section-description {
        position: absolute;
        top: 50vh;
        padding: 0 1rem;
        font-size: 1.3rem;
    }

    .hero-section .hero-section-images img:nth-child(2) {
        height: 30%;
        bottom: 40%;
    }

    .hero-section .scroll-down {
        bottom: 10%;
    }

}