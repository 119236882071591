.page-statics {
    width: 100%;
    padding: 2rem 1rem;
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 1rem;
}


.counter {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    /* background: var(--primary); */
    padding: 1rem;
    border-radius: 10px;
    font-size: var(--medium-font);
    font-family: 'Poppins', sans-serif;
    color: var(--text);
    box-shadow: 0px 15px 50px -20px var(--shadow);
    background: var(--bg);
    border: 1px solid rgba(100, 100, 100, 0.223);
    transition: background .3s ease-in-out;
}

.counter:hover {
    color: var(--white);
    background: var(--primary);
}

.counter .icon {
    padding: 1rem;
}

.counter .icon i {
    margin: 0;
    font-size: var(--heading);
}

.counter .counter-number {
    font-size: var(--heading);
    transition: .3s ease-in-out;
}

.counter .counter-label {
    font-weight: 500;
}