.theme {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

/* header.active .theme .icon i {
    color: var(--white);
} */

.theme .icon {
    cursor: pointer;
    padding: 1.5rem;
    border-radius: 50%;
    font-size: var(--large-font);
    user-select: none;
    color: var(--text);
    /* box-shadow: 0 1px 5px -2px var(--black), inset 0 1px 2px -5px var(--black); */
    transition: box-shadow .3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.theme .icon:hover {
    box-shadow: 0 .5rem .5rem -.5rem var(--black);
}