.footer {
    /* background: var(--secondary); */
    position: relative;
    margin-top: auto;
    z-index: 5;
    padding: 1rem 5%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: var(--text);
    justify-self: flex-end;
    box-shadow: -10px 0 50px 0px var(--shadow);
}

.footer .footer-main-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem 0;
}

.footer-main-section .section {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.footer-main-section .section .heading {
    font-weight: 700;
    font-size: var(--heading);
    padding: 1rem 0;
}

.footer .section .content {
    padding: .5rem;
    font-weight: 400;
    font-size: var(--medium-font);
    margin-left: 1rem;
}


.content .social-links a,
.content .quick-links a {
    display: flex;
    align-items: flex-end;
    gap: .3rem;
    padding-bottom: .5rem;
    cursor: pointer;
}

.content a .icon {
    width: 2rem;
    height: 2rem;
    filter: grayscale(1);
    transition: .3s;
}

.content a .icon i {
    margin: 0;
    color: var(--text);
    font-size: var(--small-font);
}

.content a .text {
    transition: .3s;
    display: flex;
    align-items: center;
    color: var(--text);
}

.social-links:hover .icon {
    filter: grayscale(0);
}

.social-links:hover .text,
.quick-links:hover .text {
    text-shadow: 1px 1px 1px var(--shadow);
}


.footer .copyright {
    display: flex;
    font-weight: 500;
    justify-content: center;
    padding: .5;
    font-size: var(--medium-font);
    position: relative;
    color: rgb(163, 163, 163);
    text-align: center;
}

.copyright .credit {
    position: absolute;
    top: -2rem;
    left: 0;
    font-size: var(--small-font);
    cursor: pointer;
    color: transparent;
    transition: .3s;
    user-select: none;
    font-family: 'Poppins', sans-serif;
}

.copyright .credit:hover {
    color: var(--text);
    text-shadow: 1px 1px 1px var(--sshadow);
}

.copyright .credit:hover span {
    color: #ff4060;
}

.credit span {
    font-weight: bold;
    font-family: 'Babylonica', cursive;
}

@media screen and (max-width:700px) {
    .footer .footer-main-section {
        flex-direction: column;
        align-items: center;
    }


    .footer-main-section .section {
        width: 100%;
        align-items: flex-start;
    }

    .copyright .credit {
        right: 0;
    }

}

@media screen and (max-width:850px) {
    .footer .footer-main-section .footer-image {
        display: none;
    }
}