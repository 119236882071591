.button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background: var(--primary);
    color: var(--white);
    border: .1rem solid transparent;
    border-radius: 10px;
    font-size: var(--medium-font);
    min-width: max-content;
    padding: 1.5rem;
    cursor: pointer;
    transition: .3s;
    user-select: none;
}

.button:hover {
    color: var(--primary);
    background: var(--bg);
    border: .1rem solid var(--primary);
    box-shadow: 0 1rem 2rem -1rem var(--black);
}
.button:disabled {
    background: var(--bg);
    box-shadow: 0 1rem 2rem -1rem var(--black);
}

.button .text {
    display: grid;
    place-items: center;
}

.button .icon i {
    margin-top: 0;
}

.button .icon i,
.button .text {
    color: inherit;
    font-weight: bold;
    font-size: var(--medium-font);
}

.button:disabled .icon i,
.button:disabled .text,
.button:disabled:hover .icon i,
.button:disabled:hover .text{
    color: rgb(83, 83, 83);
}


.button:disabled:hover {
    cursor: not-allowed;
    border: .1rem solid transparent;
}


.button .loader-spin {
    animation: spin 3s ease infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}