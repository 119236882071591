.password-field {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.password-field .field-heading {
    font-size: var(--large-font);
}

.password-field .field-body {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
}



.field-body .col {
    padding: 1rem;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}






@media screen and (max-width:400px) {


    .password-field .field-body {
        padding: 0;
    }
}