.our-experties-section {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
}

.our-experties-section .section-heading {
    font-size: var(--heading);
    align-self: center;
    color: var(--primary);
    margin-bottom: 3rem;
}

.our-experties-section .section-body {
    padding: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

}


.section-body .image-box {
    display: flex;
}


.image-box img {
    width: auto;
    height: 100%;
}

.section-body .card-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    grid-auto-rows: 30rem;
    padding: 0 5rem;
    gap: 3rem;
}

.card-container .facility-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    background: var(--bg);
    box-shadow: 5px 10px 10px -5px var(--shadow);
    border: 1px solid rgba(100, 100, 100, 0.223);
}

.card-container .facility-card:hover h3 {
    transform: scale(1.5);
    color: var(--white);
}

.card-container .facility-card:hover img {
    transform: scale(1.2);
}

.card-container .facility-card h3 {
    position: relative;
    transition: .3s;
    z-index: 1;
    font-size: var(--heading);
    color: var(--text);
}


.card-container .facility-card img {
    position: absolute;
    object-position: center;
    object-fit: contain;
    opacity: .6;
    transition: .3s;
}

.card-container .facility-card.training:hover {
    background: var(--primary);

}

.card-container .facility-card.training img {
    transform: scale(1.3);

}

.card-container .facility-card.training:hover img {
    transform: scale(1.5);
}

.card-container .facility-card.internship:hover {
    background: var(--secondary);
}

@media screen and (max-width:920px) {

    .section-body .image-box {
        display: none;
    }
}

@media screen and (max-width:600px) {

    .section-body .image-box {
        display: none;
    }

    .section-body .card-container {
        padding: 0 1rem;
    }

}