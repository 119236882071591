.navbar {
    padding: 1rem;
    flex: 1;
}

.navbar.active {
    width: 100%;
    padding: 1rem;
}

.navbar .nav-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem;
}

.nav-list .nav-items {
    width: auto;
}

.nav-items .nav-links {
    border-radius: 10px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: max-content;
    padding: 1rem 1.5rem;
    gap: .5rem;
    transition: .3s;
}

/* .header.active .nav-links .icon i,
.header.active .nav-links .text {
    color: var(--white);
} */


.header .nav-links .icon i,
.header .nav-links .text {
    font-size: var(--medium-font);
}


.nav-items .nav-links:hover {
    background: var(--hover);
}

.nav-items .nav-links:hover .icon i,
.nav-items .nav-links:hover .text {
    color: var(--primary);
}



/* Resposive */

@media screen and (max-width:500px) {
    .navbar .nav-list {
        flex-wrap: wrap;
    }

}