.feedback-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 10px;
    flex-shrink: 0;
    margin: 0 auto;
    transition: .3s;
    user-select: none;
    background: var(--bg-light);
}


.feedback-card .card-heading {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    flex-shrink: 0;
    padding: 1rem;
    margin-bottom: 1rem;
}

.card-heading .image {
    width: 15rem;
    height: 15rem;
    padding: .1rem;
    flex-shrink: 0;
    user-select: none;
    border-radius: 50%;
    border: .2rem solid var(--primary);
    position: relative;
}

.card-heading .image img {
    border-radius: 50%;
    object-fit: fill cover;
    object-position: center;
}

.card-heading .image .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10rem;
    color: var(--primary);
}

.card-heading .heading-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    font-size: var(--medium-font);
    gap: .5rem;
    font-weight: 500;
    text-align: center;
}

.heading-details .name {
    font-weight: 800;
    font-size: var(--large-font);
    color: var(--primary);
}

.heading-details .designation {
    font-weight: 700;
    font-style: italic;
}
.feedback-card .card-body {
    padding: 1rem 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
}

.card-body .review {
    font-size: var(--medium-font);
    position: relative;
    line-height: 1.5em;
    z-index: 2;
    padding: 0 2rem;
}

.feedback-card .card-body.short {
    min-height: unset; /* Remove min-height as it might be forcing the container to expand */
    height: calc(1.5em * 7); /* Set explicit height instead */
    overflow: hidden;
}
.card-body.short .review {
    min-height: unset; /* Remove min-height as it might be forcing the container to expand */
    height: calc(1.5em * 7); /* Set explicit height instead */
    display: -webkit-box;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    line-clamp: 7;
    -webkit-line-clamp: 7;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

.card-body .quote {
    position: absolute;
    width: 4rem;
    height: 4rem;
    z-index: 1;
    filter: contrast(5);
}

.card-body .quote:first-child {
    top: 0;
    left: 0;
}

.card-body .quote:last-child {
    bottom: 0;
    right: 0;
}


@media screen and (max-width:600px) {

    .left-button,
    .right-button {
        width: 3rem;
        height: 7rem;
    }

    .feedback-card {
        width: 100%;
    }

    .card-body .review {
        padding: 0;
    }

    .feedback-section {
        padding: 0;
    }
}