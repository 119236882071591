.events-page .page-body {
    width: 50%;
}

.events-container {
    display: flex;
    flex-direction:column;
   
    
    margin: 10px;
}

.events-container .events {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: var(--bg);
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    border-radius: 10px;
    cursor: pointer;
}

.events-container .events .events-image {
    width: 100%;
    height: 20rem;
}

.events-container .events .events-text {
    color: var(--primary);
    font-size: var(--large-font);
    text-align: center;
    font-weight: 600;
    padding: 1rem 0;
}