.feedback-section {
    min-height: calc(100svh - 10rem);
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;
}

.feedback-section .section-heading {
    font-size: var(--heading);
    align-self: center;
    color: var(--primary);
    margin-bottom: 3rem;
}

.feedback-section .section-body {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-body .feedback-container {
    width: 100%;
    padding: 1rem;
    display: grid;
}

.feedback-container .swiper-wrapper {
    width: 100%;
    padding: 5px;

}
.feedback-container .swiper-slide-active .feedback-card
{
    border: 1px solid rgba(100, 100, 100, 0.223);
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: linear-gradient(to bottom, var(--bg), transparent);
}

.feedback-container .swiper-pagination {
    --swiper-pagination-bottom:-3px;
}
.feedback-container .swiper-button-next,
.feedback-container .swiper-button-prev {
    color: var(--secondary);
    --swiper-navigation-size: 35px;
    width:calc(var(--swiper-navigation-size)/ 44 * 50);
    height: calc(var(--swiper-navigation-size)/ 44 * 60);
    border-radius: 10px;
    background: var(--bg);
    box-shadow: 0px 0px 10px 0px var(--shadow);
    transition: all .2s ease-in-out;
}
.feedback-container .swiper-button-next:hover,
.feedback-container .swiper-button-prev:hover {
    color: var(--primary);
    transform: scale(1.1);
}

.feedback-container .swiper-pagination-bullet {
    background: var(--primary);
}

/* .feedback-container .left-button,
.feedback-container .right-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--heading);
    padding: .5rem;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 7rem;
    color: var(--secondary);
} */

/* .feedback-container .left-button i,
.feedback-container .right-button i {
    font-size: 2rem;
    color: inherit;
}

.feedback-container .left-button:hover,
.feedback-container .right-button:hover {
    background: var(--bg);
}

.feedback-container .left-button {
    left: 0rem;
}

.feedback-container .right-button {
    right: 0rem;
} */


.feedback-container .feedback-card-container {
    display: flex;
}


@media screen and (max-width:500px) {

    .feedback-container .swiper-button-next,
    .feedback-container .swiper-button-prev {
        display: none;
    }
}