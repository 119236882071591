.course-feedback-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.course-feedback-section .section-heading {
    font-size: var(--heading);
    margin: 1rem;
    padding: 1rem 0;
}

.course-feedback-section .section-body {
    padding: 1rem;
    background: var(--bg);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.course-feedback-section .section-body p {
    font-size: var(--medium-font);
}


.section-body .feedbcak-popup {
    padding: .5rem;
}

.feedbcak-popup form {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}