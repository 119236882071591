.circular-progress-bar {
    position: relative;
}

.circle-background {
    fill: none;
    stroke: var(--bg-light);
}

.circle-progress {
    fill: none;
    stroke: var(--primary);
    stroke-linecap: round;
    stroke-linejoin: round;
}

.progress-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--medium-font);
    font-weight: 600;
}