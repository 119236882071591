.page-body .certificate-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.certificate-section .section-heading {
    font-size: var(--heading);
    margin: 1rem;
    padding: 1rem 0;
}

.certificate-section .section-body {
    padding: 1rem;
    background: var(--bg);
}

.section-body .certificate-criteria {
    font-size: var(--medium-font);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
}

.section-body .certificate-criteria span {
    color: var(--primary);
    font-weight: bold;
    background: var(--bg);
    padding: .2rem .5rem;
    border-radius: 10px;
}

.section-body .current-percentage {
    font-size: var(--medium-font);
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.section-body .current-percentage span {
    padding: .5rem 1rem;
    font-weight: bold;
    border-radius: 10px;
    color: var(--black);
}

.section-body .certificate-preview{
    width: 100%;
    height: 500px;
}

.section-body .certificate-download {
    padding: 1rem 0;
}

.section-body .dummy-certificate-img {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.section-body .dummy-certificate-img img {
    width: 50%;
    border-radius: 10px;
}



/* Responsive */

@media screen and (max-width:600px) {
    .section-body .dummy-certificate-img img {
        width: 100%;
    }
}