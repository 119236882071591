.dashboard-page {
    align-items: start;
    justify-content: start;
}

.dashboard-page .page-heading {
    font-size: var(--heading);
    align-self: center;
    padding: 1rem 3rem;
    margin: 1rem 0;
}

.dashboard-page .page-heading .user-name {
    text-align: center;
}

.dashboard-page .page-heading .college-name {
    text-align: center;
    opacity: .7;
}

.page-body .page-body-heading {
    font-size: var(--heading);
    align-self: center;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-body-heading .share-work {
    display: flex;
    gap: 10px;
}

.share-work button {
    display: grid;
    place-items: center;
}

.share-work .copy-link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--bg-dark);
    border: none;
    cursor: pointer;
    font-size: 2rem;
    color: var(--text);
    position: relative;
}

.copy-link:hover .tooltip {
    opacity: 1;
    top: -110%;
}

.copy-link .tooltip {
    position: absolute;
    top: 0%;
    transform: translateY(100%);
    background: var(--text);
    color: var(--bg-dark);
    font-size: 1.2rem;
    padding: .3rem .5rem;
    border-radius: 10px;
    opacity: 0;
    transition: .3s ease;
    font-weight: 600;
}

.dashboard-page .page-body {
    width: 100%;
}

.page-body .score-card-container,
.page-body .task-list-container {
    padding: 1rem;
}

.page-body .task-list-container {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
}

.score-card-container .card:nth-child(1) {
    --button-color: var(--success);
}

.score-card-container .card:nth-child(2) {
    --button-color: var(--warning);
}

.score-card-container .card:nth-child(3) {
    --button-color: var(--danger);
}