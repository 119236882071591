.input-box {
    position: relative;
    width: 100%;
}

.input-box.icon .icon {
    position: absolute;
    left: 0;
    width: 4rem;
    height: 100%;
    display: grid;
    place-items: center;
    border-right: 1px solid var(--secondary);
}

.input-box.icon input,
.input-box.icon textarea {
    padding-left: 5rem;
}


.input-box input,
.input-box textarea {
    font-size: var(--medium-font);
    width: 100%;
    height: 5rem;
    border-radius: 10px;
    color: var(--text);
    background: var(--bg);
    border: .1rem solid var(--secondary);
    padding: 1rem;
    padding-top: 3rem;
}

.input-box textarea {
    resize: none;
    min-height: 30vh;
    width: 100%;
    font-family: inherit;
}

.input-box input:disabled,
.input-box input:disabled~.icon,
.input-box input:disabled~label {
    background: transparent;
    color: var(--faded-text);
    user-select: none;
}

.input-box input~label,
.input-box textarea~label {
    left: 1rem;
    background: transparent;
    position: absolute;
    top: 50%;
    color: var(--text);
    transform: translateY(-50%);
    font-size: var(--medium-font);
    border-radius: 10px;
    pointer-events: none;
    user-select: none;
    transition: .3s;
    filter: opacity(.7);
    padding: .5rem;
}

.input-box.icon input~label,
.input-box.icon textarea~label {
    left: 5rem;
}

.input-box input:focus~label,
.input-box input:not(:placeholder-shown)~label,
.input-box textarea:focus~label,
.input-box textarea:not(:placeholder-shown)~label {
    top: .3rem;
    font-size: var(--small-font);
    color: var(--primary);
    transform: translateY(0%);
    font-weight: 600;
    line-height: 1rem;
    filter: opacity(1);
}