.task-card {
    background: var(--bg);
    padding: 1rem;
    position: relative;
    border-radius: 10px;
    transition: .3s;
    place-self: flex-start;
    width: 100%;
    cursor: pointer;
    border: 1px solid var(--secondary);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
}


.task-card:hover {
    box-shadow: 0 1rem 5rem -3rem var(--black);
}

.task-card .task-card-header {
    display: flex;
    align-items: center;
    font-size: var(--large-font);
    padding: 1rem 0;
    gap: 1rem;
}




.task-card-header .task-number {
    font-size: 3rem;
    font-weight: 500;
    color: var(--faded-text);
}




.task-card-header .task-name {
    color: var(--primary);
    font-weight: 500;
}


.task-card .task-card-status {
    align-self: flex-end;
    display: flex;
    padding: .5rem;

}

.task-card-status .status-label {
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 10px;
    gap: .5rem;
}

.status-label .icon i {
    color: var(--black);
    margin: 0;
}

.status-label .icon~.text {
    font-weight: 500;
    color: var(--black);
    font-size: var(--small-font);
}

.task-card .task-links-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    gap: 1rem;
    position: absolute;
    bottom: -100%;
    height: 100%;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: linear-gradient(var(--bg), var(--secondary));
    transition: .3s ease;
    cursor: default;
}

.task-card:hover .task-links-container {
    bottom: 0;
}

.task-links-container .task-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    padding: 1rem 2rem;
    border-radius: 10px;
    border: .2rem solid var(--white);
    user-select: none;
}

.task-links-container div.task-links {
    color: var(--faded-text);
    border: .2rem solid var(--faded-text);
    cursor: default;
}

.task-links-container a.task-links:hover {
    color: var(--primary);
    background: var(--white);
    border-color: var(--primary);
}