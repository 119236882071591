.enroll-page {
    align-items: flex-start;
}

.enroll-page .page-content {
    width: 100%;
    flex: 1;
    display: grid;
    gap: 2rem;
    padding: 1rem;
    grid-template-columns: 2fr 1.2fr;
    position: relative;
    justify-content: space-between;
}

.page-content .course-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.course-header .cover-image {
    width: 100%;
    height: 20rem;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
}

.course-header .cover-image img {
    align-self: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.course-header .cover-default-image {
    font-size: 8rem;
    font-weight: 800;
    text-align: center;
    color: var(--primary);
    font-family: 'Poppins', sans-serif;
    padding-block: 3rem;
    text-shadow: .5rem .5rem .5rem var(--bg-dark), .6rem .6rem .5rem var(--bg-light), 1rem 1rem .5rem var(--bg-dark);
    font-size: italic;
    z-index: 1;
    width: 100%;
    height: 100%;
    line-height: 15rem;
    background: var(--bg);
    transition: .3s;
    user-select: none;
    display: grid;
    place-items: center;
}

.course-header .course-name {
    font-size: var(--heading);
    color: var(--primary);
    padding: 1rem;
}


.course-header .course-price-duration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}

.course-price-duration .course-duration,
.course-price-duration .course-price {
    display: flex;
    gap: .5rem;
    align-items: center;
}

.course-duration .icon i,
.course-duration .text,
.course-price .icon i,
.course-price .text {
    color: var(--text);
    font-size: var(--large-font);
}





.course-details .course-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}


.course-body .course-technologies {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.course-technologies .technologies-heading {
    font-size: var(--large-font);
}

.course-technologies .technologies-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.technologies-body .techs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
    background: var(--bg);
    font-size: var(--medium-font);
    gap: .5rem;
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
}




.page-content .payment-form {
    padding: 0 1rem;
    background: var(--bg-light);
}

.page-content .form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


@media screen and (max-width:900px) {
    .enroll-page .page-content {
        grid-template-columns: minmax(30rem, 1fr);
    }

    .course-header .cover-image {
        height: 25rem;
    }

}

@media screen and (min-width:1024px) {
    .course-header .cover-image {
        height: 25rem;
    }

}