.gallery-page {
    align-items: center;
}

.gallery-page .page-body {
    width: 100%;
}

.page-body .gallery-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    grid-auto-rows: 30rem;
    user-select: none;
    transform-style: preserve-3d;
}

.gallery-grid .tilt-container {
    border: .3rem solid var(--primary);
    border-radius: 10px;
}


.gallery-grid .tilt-container:hover {
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.gallery-grid .tilt-container:nth-child(7n) {
    grid-row: span 2;
    grid-column: span 2;
}

.gallery-grid .tilt-container:nth-child(1) {
    grid-column: span 2;
}

.gallery-grid .tilt-container:nth-child(3) {
    grid-row: span 2;
}

.gallery-grid .tilt-container:nth-child(8) {
    grid-column: span 2;
}

.gallery-grid .tilt-container:nth-child(12) {
    grid-column: span 2;
}


.tilt-container img {
    object-fit: cover;
    object-position: center;
    -webkit-user-drag: none;
}

.expanded-image {
    position: absolute;
    width: calc(100% + 1rem);
    height: calc(100vh - 8rem);
    left: -10px;
    top: -10.5rem;
    max-height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    padding: 1rem;
    z-index: 6;
}

.expanded-image img {
    object-fit: contain;
}

.expanded-image .cross {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 3rem;
    color: var(--white);
    cursor: pointer;
}


@media screen and (max-width:1250px) {

    .gallery-grid .tilt-container:nth-child(7n) {
        grid-row: span 1;
        grid-column: span 1;
    }

    .gallery-grid .tilt-container:nth-child(6) {
        grid-column: span 2;
        grid-row: span 2;
    }

    .gallery-grid .tilt-container:nth-child(11) {
        grid-column: span 2;
    }

}

@media screen and (max-width:940px) {

    .gallery-grid .tilt-container:nth-child(7n) {
        grid-column: span 1;
        grid-row: span 1;
    }

    .gallery-grid .tilt-container:nth-child(5) {
        grid-column: span 1;
        grid-row: span 2;
    }

    .gallery-grid .tilt-container:nth-child(6) {
        grid-column: span 1;
        grid-row: span 1;
    }

    .gallery-grid .tilt-container:nth-child(11) {
        grid-column: span 2;
    }

    .gallery-grid .tilt-container:nth-child(12) {
        grid-column: span 1;
    }


}

@media screen and (max-width:500px) {

    .gallery-grid .tilt-container:nth-child(2n) {
        grid-column: span 1;
        grid-row: span 1;
    }

    .gallery-grid .tilt-container:nth-child(2n+1) {
        grid-column: span 1;
        grid-row: span 1;
    }


}